<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import CreateVideo from '@components/video/createvideo'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    // PageHeader
    CreateVideo,
  },
  data() {
    return {
      show: false,
      category_code: 'M001',
      route_back: '/category/trainingbasic',
      form: {
        name: '',
        description: '',
      },
    }
  },
  mounted() {},
  methods: {},
}
</script>

<template>
  <Layout>
    <b-overlay :show="show" rounded="sm">
      <div class="row page-title align-items-center">
        <div class="col-md-3 col-xl-6">
          <h4 class="mb-1 mt-0">Create Video Workout</h4>
        </div>
        <div class="row mt-2" style="height:100%; width:100%;">
          <div class="col-12">
            <div class="card">
              <CreateVideo :category_code="this.category_code" :route_back="this.route_back"></CreateVideo>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
